import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'webp',
    standalone: false
})
export class WebpPipe implements PipeTransform {
  transform(urlImage: string): any {
    if (urlImage) {
      const urlImageArray = urlImage.split('.');
      urlImageArray[urlImageArray.length - 1] = 'webp';
      return urlImageArray.join('.');
    }
    return urlImage;
  }
}
